import type { Image, Video } from '@design-system/components/UI/UIMedia.props'

// https://cloudinary.com/documentation/video_optimization
export const optimizeVideo = (
  url: string,
  quality: string,
  aspectRatio?: string,
  format?: string
): string => {
  const uploadPath = '/upload/'
  const uploadIndex = url.indexOf(uploadPath)

  if (uploadIndex === -1) {
    return url
  }

  const qualityPath =
    quality === undefined || quality === 'auto' ? 'q_auto' : `q_auto:${quality}`

  const formatVideo = 'f_auto,'

  const ratio = aspectRatio
    ? `,c_fill,${formatVideo}ar_${adaptRatio(aspectRatio)}/`
    : '/'

  return `${url.slice(0, uploadIndex + uploadPath.length)}${qualityPath}${ratio}${url.slice(uploadIndex + uploadPath.length)}#t=0.1`
}

export const adaptRatio = (aspectRatio: string): string => {
  const ratio = '/'
  const ratioIndex = aspectRatio.indexOf(ratio)

  if (ratioIndex === -1) {
    return aspectRatio
  }

  return `${aspectRatio.slice(0, ratioIndex)}:${aspectRatio.slice(ratioIndex + ratio.length)}`
}

/**
 * Creates a media object for a Homepage card.
 *
 * @param {(Image | Video)} type - The type of media, either Image or Video
 * @param {string} src - The source URL of the media
 * @param {string} alt - The alternative text for the media, used for accessibility purposes (applicable only for images)
 *
 * @returns {(Image | Video)} - Returns an object representing either an image or a video, with properties specific to the type
 */
export const createMedia = (
  type: 'image' | 'video',
  src: string,
  alt: string
): Image | Video => {
  if (type === 'image') {
    return {
      type: 'image',
      srcImage: { secure_url: src },
      alt: alt,
      sizes: 'lg:25vw 100vw',
    }
  } else {
    return {
      type: 'video',
      autoplay: true,
      muted: true,
      showCommands: false,
      quality: 'best',
      srcVideo: { secure_url: src },
    }
  }
}

const removeFParameter = (url: string) => {
  return url.replace(/f_[^,]+,?/, '') // This will remove 'f_*' and the trailing comma if it exists
}

const addSoAuto = (url: string) => {
  return url.replace(/(q_auto)/, 'so_auto/$1') // Inserts 'so_auto,' before 'q_auto'
}

const addFAuto = (url: string) => {
  return url.replace(/(q_auto)/, 'f_auto/$1') // Inserts 'f_auto,' before 'q_auto'
}

export const posterVideo = (src: string | undefined) => {
  if (!src) return undefined
  const config = useRuntimeConfig()
  const validExtensions = ['.mp4', '.mov', '.jpg', '.png']
  const hasValidExtension = validExtensions.some(extension =>
    src.endsWith(extension)
  )
  const result = hasValidExtension
    ? addFAuto(removeFParameter(src)).replace(/\.[^/.]+$/, '.webp')
    : addFAuto(removeFParameter(src)) + '.webp'

  return result.startsWith('https://')
    ? result
    : `${config.public.cloudinary}${result}`
}
